import { ExpandLess, KeyboardArrowRight } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  SvgIcon,
  SvgIconProps,
  Theme,
  useMediaQuery
} from '@mui/material';
import { Box, styled } from '@mui/system';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavbarContext } from '../../contexts/NavBarButtonContext';
import { useUser } from '../../contexts/UserContext';
import useLogout from '../../hooks/useLogout';
import { useUserContext } from '../../hooks/useUserContext';
import { checkRoles } from '../../utils/functions';
import { toTitle } from '../../utils/regex';
import StringAvatar from '../StringAvatar';
import {
  advancesSection,
  assistanceItem,
  assistanceRejectedItem,
  assistancesSection,
  billItem,
  companyItem,
  dashboardItem,
  dashboardSection,
  disbursedPaymentRequestItem,
  expenseItem,
  factcilPortfolio,
  financialSection,
  helpItem,
  incomeItem,
  logoutItem,
  operationProjectsSection,
  paymentRequestItem,
  payrollItem,
  payrollSection,
  pendingDisbursementPaymentRequestItem,
  portfolioToRecover,
  profileItem,
  projectItem,
  projectSection,
  providersItem,
  referralSection,
  rejectedPaymentRequestItem,
  rejectedProjectItem,
  reportsSeccion,
  serviceItem,
  userDataItem,
  userDataSection
} from './ItemsSections';

interface AppBarProps extends SvgIconProps {
  is_selected?: string;
  iconadjust?: string;
  component: any;
}

const StyledSvgIcon = styled(SvgIcon, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ is_selected = 'false', iconadjust }) => {
  return {
    opacity: is_selected.toString() === 'true' ? '1' : '0.8',
    '& > g': { opacity: is_selected.toString() === 'true' ? '1' : '0.8' },
    ...(iconadjust && {
      fontSize: iconadjust,
      marginLeft: '2px'
    })
  };
});

type Props = {
  isOpen?: boolean;
  menuScrollRef: any;
};

const ItemList: FC<Props> = ({ isOpen = false, menuScrollRef }) => {
  useUserContext();
  const user = useUser();
  const history = useLocation();
  const navigate = useNavigate();
  const userCollapsableRef = useRef<HTMLUListElement>(null);
  const { t } = useTranslation('common');
  const { mutate: logout } = useLogout();
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
  const navBarContext = useNavbarContext();

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleOpenUserData = () => {
    setIsCollapsibleOpen(!isCollapsibleOpen);
  };

  const handleLogout = () => {
    logout();
  };

  const ondemandProjectItems = () => {
    const projectItems = [serviceItem(t), companyItem(t)];
    if (user?.currentUser.is_assistance) {
      projectItems.push(billItem(t));
    }
    return projectItems;
  };

  const getUserData = React.useMemo(() => {
    if (user?.currentUser) {
      const { first_name: firstName } = user.currentUser;
      return `${toTitle(firstName)}`;
    }
    return '';
  }, [user]);

  const adminList = [
    userDataSection([
      userDataItem(getUserData, handleOpenUserData, [
        logoutItem(t, handleLogout)
      ])
    ])
  ];

  const selfEmployeeList = [
    dashboardSection([dashboardItem(t), assistanceItem(t)]),
    projectSection(t, [serviceItem(t), companyItem(t), billItem(t)]),
    financialSection(t, [incomeItem(t), expenseItem(t)]),
    payrollSection(t, [payrollItem(t)]),
    referralSection([helpItem(t)]),
    userDataSection([
      userDataItem(getUserData, handleOpenUserData, [
        profileItem(t),
        logoutItem(t, handleLogout)
      ])
    ])
  ];

  const ondemandList = [
    dashboardSection([
      dashboardItem(t),
      paymentRequestItem(t),
      assistanceItem(t)
    ]),
    projectSection(t, ondemandProjectItems()),
    financialSection(t, [incomeItem(t), expenseItem(t)]),
    payrollSection(t, [payrollItem(t)]),
    referralSection([helpItem(t)]),
    userDataSection([
      userDataItem(getUserData, handleOpenUserData, [
        profileItem(t),
        logoutItem(t, handleLogout)
      ])
    ])
  ];

  const operationalList = [
    dashboardSection([
      dashboardItem(t),
      reportsSeccion(t),
      factcilPortfolio(t),
      portfolioToRecover(t),
      providersItem(t)
    ]),
    operationProjectsSection([projectItem(t), rejectedProjectItem(t)]),
    advancesSection([
      paymentRequestItem(t, true),
      rejectedPaymentRequestItem(t),
      pendingDisbursementPaymentRequestItem(t),
      disbursedPaymentRequestItem(t)
    ]),
    userDataSection([
      userDataItem(getUserData, handleOpenUserData, [
        logoutItem(t, handleLogout)
      ])
    ]),
    assistancesSection([assistanceItem(t), assistanceRejectedItem(t)])
  ];

  const financialList = [
    dashboardSection([dashboardItem(t), paymentRequestItem(t), projectItem(t)]),
    userDataSection([
      userDataItem(getUserData, handleOpenUserData, [
        logoutItem(t, handleLogout)
      ])
    ])
  ];

  const allyList = [
    dashboardSection([dashboardItem(t), projectItem(t)]),
    userDataSection([
      userDataItem(getUserData, handleOpenUserData, [
        logoutItem(t, handleLogout)
      ])
    ])
  ];

  const accountingOfficerList = [
    dashboardSection([
      dashboardItem(t),
      disbursedPaymentRequestItem(t),
      providersItem(t)
    ]),
    userDataSection([
      userDataItem(getUserData, handleOpenUserData, [
        logoutItem(t, handleLogout)
      ])
    ])
  ];

  const isActiveMenuItem = (menuPath: string) => {
    if (history.pathname.substring(0, menuPath.length) === menuPath) {
      return true;
    }
    return false;
  };

  const getMenuItem = (
    item: any,
    isSelected: boolean,
    isCollapsible: boolean = false,
    isCollapsedItem: boolean = false
  ) => {
    return (
      <PopupState variant="popover" popupId="userPopup" key={item.key}>
        {(popupState) => (
          <>
            <ListItemButton
              disableTouchRipple
              selected={isSelected ? isSelected : false}
              onClick={() => {
                if (item.handleFunction) {
                  item.handleFunction();
                } else {
                  navigate(item.href);
                }
                if (item.key !== 'user-data') navBarContext.handleChangeOpen();
              }}
              key={item.key}
              {...(isCollapsible && { sx: { maxWidth: '16rem' } })}
              {...(item.isHighlighted && { className: 'hightlighted' })}
              {...(!mdDown && isCollapsible && bindTrigger(popupState))}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center' }}
                {...item.boxProps}
                id={`${item.key}_navitem`}
              >
                {item.icon && (
                  <ListItemIcon
                    sx={{ minWidth: 47, ml: isCollapsedItem ? 2 : 1 }}
                  >
                    <StyledSvgIcon
                      component={item.icon}
                      inheritViewBox
                      color="primary"
                      is_selected={isSelected.toString()}
                      iconadjust={item.iconAdjust}
                    ></StyledSvgIcon>
                  </ListItemIcon>
                )}
                {item.avatar && <StringAvatar avatarName={item.avatar} />}
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    sx: {
                      ...(isCollapsible && {
                        ml: '1rem',
                        whiteSpace: 'pre-wrap',
                        maxHeight: '2.4rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }),
                      fontSize: '14px',
                      color: (theme) => theme.palette.primary.main
                    }
                  }}
                />
                {isCollapsible ? (
                  isCollapsibleOpen ? (
                    <ExpandLess />
                  ) : (
                    <KeyboardArrowRight />
                  )
                ) : null}
              </Box>
            </ListItemButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left'
              }}
            >
              {item.popoverItems &&
                item.popoverItems.map((popoverItem: any, index: Number) => {
                  return (
                    <ListItemButton
                      disableTouchRipple
                      selected={isSelected ? isSelected : false}
                      onClick={() => {
                        if (popoverItem.handleFunction) {
                          popoverItem.handleFunction();
                        } else {
                          navigate(popoverItem.href);
                        }
                        popupState.close();
                        navBarContext.handleChangeOpen();
                      }}
                      key={popoverItem.key}
                      sx={{
                        ...(index === 0 && { pt: 1, pb: 0 }),
                        ...(index === item.popoverItems.length && {
                          pb: 1,
                          pt: 0
                        })
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pr: 2,
                          pt: 0,
                          pb: 0
                        }}
                        {...popoverItem.boxProps}
                      >
                        {popoverItem.icon && (
                          <ListItemIcon sx={{ minWidth: 47 }}>
                            <StyledSvgIcon
                              component={popoverItem.icon}
                              inheritViewBox
                              color="primary"
                              is_selected={isSelected.toString()}
                              iconadjust={popoverItem.iconAdjust}
                            ></StyledSvgIcon>
                          </ListItemIcon>
                        )}
                        {popoverItem.avatar && (
                          <StringAvatar avatarName={popoverItem.avatar} />
                        )}
                        <ListItemText
                          primary={popoverItem.text}
                          primaryTypographyProps={{
                            sx: {
                              fontSize: '14px',
                              color: (theme) => theme.palette.primary.main,
                              pr: 5
                            }
                          }}
                        />
                      </Box>
                    </ListItemButton>
                  );
                })}
            </Popover>
          </>
        )}
      </PopupState>
    );
  };

  const renderMenuListHandler = useMemo(() => {
    let roleMenuList: any[] = [];
    if (checkRoles(['ADMIN'])) roleMenuList = adminList;
    if (checkRoles(['SELFEMPLOYEE', 'OPS', 'FREE_SELFEMPLOYEE']))
      roleMenuList = selfEmployeeList;
    if (checkRoles(['OPERATIONS'])) roleMenuList = operationalList;
    if (checkRoles(['FINANCIAL'])) roleMenuList = financialList;
    if (checkRoles(['ONDEMAND'])) roleMenuList = ondemandList;
    if (checkRoles(['ACCOUNTING_OFFICER']))
      roleMenuList = accountingOfficerList;
    if (checkRoles(['ALLY'])) roleMenuList = allyList;
    return roleMenuList.map(
      (menuList: any) =>
        !menuList.hidden && (
          <List
            {...(menuList.isCollapsible && { ref: userCollapsableRef })}
            {...(menuList.title && {
              subheader: (
                <ListSubheader
                  disableSticky
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {menuList.title}
                </ListSubheader>
              )
            })}
            key={menuList.key}
            sx={{
              ...(menuList.isCollapsible &&
                !mdDown &&
                isOpen && {
                  position: 'fixed',
                  background: 'white',
                  zIndex: '9',
                  bottom: '0',
                  width: '14rem'
                })
            }}
          >
            {menuList.items.map((item: any, index: number) => {
              const isSelected: boolean =
                item?.href || item?.activePath
                  ? isActiveMenuItem(
                      item?.activePath || `/${item.href.split('/')[1]}`
                    )
                  : false;
              const isCollapsible = menuList.isCollapsible;
              return (
                <Box component="div" key={index}>
                  {getMenuItem(item, isSelected, isCollapsible)}
                  {mdDown &&
                    isCollapsible &&
                    item.popoverItems &&
                    item.popoverItems.map((popoverItem: any) => {
                      return (
                        <Collapse
                          in={isCollapsibleOpen}
                          timeout={0}
                          unmountOnExit
                          key={item.key}
                          addEndListener={(e) => {
                            if (menuScrollRef && menuScrollRef.current) {
                              const { current: curr } = menuScrollRef;
                              setTimeout(() => {
                                if (isCollapsibleOpen) {
                                  curr.scrollTop =
                                    curr.scrollTop + e.clientHeight;
                                } else {
                                  curr.scrollTop = curr.scrollTop - 60;
                                }
                              }, 50);
                            }
                          }}
                        >
                          <List component="div" disablePadding key={item.key}>
                            {getMenuItem(popoverItem, isSelected, false, true)}
                          </List>
                        </Collapse>
                      );
                    })}
                </Box>
              );
            })}

            {menuList.divider === undefined && (
              <Divider
                variant="middle"
                sx={{
                  borderColor: (theme) => theme.palette.factcilGrey.main
                }}
              />
            )}
          </List>
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, mdDown, isActiveMenuItem, getMenuItem]);

  return (
    <Box pb={`${!mdDown && isOpen ? '5rem' : '1.5rem'}`}>
      {renderMenuListHandler}
    </Box>
  );
};

export default ItemList;
